.timelapse-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.timelapse-loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.5);
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}